const userApiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export default function (api) {
  return {
    login(data) {
      return api.post(userApiBaseUrl + "/auth/login", data, {
        noLoading: true
      });
    },

    events() {
      return api.get(userApiBaseUrl + "/inevent", {
        shouldJWT: true,
        noLoading: true
      });
    },

    joined() {
      return api.get(userApiBaseUrl + "/joined", {
        shouldJWT: true,
        noLoading: true
      });
    },

    eventsDetail(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}`, {
        shouldJWT: true
      });
    },

    devicetest() {
      return api.get(userApiBaseUrl + `/devicetest/user`, {
        noLoading: true,
        shouldJWT: true
      });
    },

    devicetestSave(data) {
      return api.post(userApiBaseUrl + `/devicetest/save`, data, {
        noLoading: true,
        shouldJWT: true
      });
    },

    logSave(data) {
      return api.post(userApiBaseUrl + `/logs/save`, data, {
        noLoading: true,
        shouldJWT: true
      });
    },

    enter(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/enter`, {
        shouldJWT: true
      });
    },

    usedFlag(id) {
      return api.post(userApiBaseUrl + `/inevent/${id}/enter`, {}, {
        shouldJWT: true,
        noLoading: true
      });
    },

    getSign(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/sign`, {
        shouldJWT: true
      });
    },

    eventsBeforeCount(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/beforecount`, {
        shouldJWT: true,
        noLoading: true
      });
    },

    getIneventFelo(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/felo`, {
        shouldJWT: true
      });
    },

    putIneventLang(id, data) {
      return api.put(userApiBaseUrl + `/inevent/${id}/lang`, data, {
        shouldJWT: true
      });
    },

    syncTime() {
      return api.get(userApiBaseUrl + `/sync/time`, {
        shouldJWT: true,
        noLoading: true
      });
    },

    // アンケート
    getEnquete(id) {
      return api.get(userApiBaseUrl + `/enquete/${id}`, {
        shouldJWT: true
      });
    },

    saveEnquete(id, data) {
      return api.post(userApiBaseUrl + `/enquete/${id}/answer`, data, {
        shouldJWT: true
      });
    },

    // 2shot
    selectSide(id, data) {
      return api.post(userApiBaseUrl + `/inevent/${id}/2shot/selectSide`, data, {
        shouldJWT: true
      });
    },

    originalPhotos(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/2shot/originalPhotos`, {
        shouldJWT: true
      });
    },

    // 2shot-拍完照选择照片组
    twoShotSelectPhoto(id, data) {
      return api.post(userApiBaseUrl + `/inevent/${id}/2shot/selectPhoto`, data, {
        shouldJWT: true
      });
    },

    // 2shot-获取抠图结果
    processedPhotos(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/2shot/processedPhotos`, {
        shouldJWT: true
      });
    },

    // 2shot-保存最终图片
    putFinalPhoto(id, data) {
      return api.put(userApiBaseUrl + `/inevent/${id}/2shot/finalPhoto`, data, {
        shouldJWT: true
      });
    },

    // 2shot-确认最终图片
    confirmFinalPhoto(id) {
      return api.post(userApiBaseUrl + `/inevent/${id}/2shot/confirmFinalPhoto`, null, {
        shouldJWT: true
      });
    },

    // 2shot-获取最终图片
    getFinalPhoto(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/2shot/finalPhoto`, {
        shouldJWT: true
      });
    },

    // pay
    eventStock(id) {
      return api.get(userApiBaseUrl + `/event/${id}/stock`, {
        shouldJWT: false
      });
    },

    eventOrder(id, data) {
      return api.post(userApiBaseUrl + `/event/${id}/order`, data, {
        shouldJWT: false
      });
    },

    eventSearchOrder(data) {
      return api.put(userApiBaseUrl + `/event/order`, data, {
        shouldJWT: false
      });
    },

    payCallBack(data) {
      return api.post(userApiBaseUrl + `/event/callback`, data, {
        shouldJWT: false
      });
    },

    getPayingOrderNoLoading(orderNo) {
      return api.get(userApiBaseUrl + `/event/order/${orderNo}`, {
        shouldJWT: false,
        noLoading: true
      });
    },

    getPayingOrder(orderNo) {
      return api.get(userApiBaseUrl + `/event/order/${orderNo}`, {
        shouldJWT: false
      });
    },

    getPayEvent(id) {
      return api.get(userApiBaseUrl + `/event/${id}`, {
        shouldJWT: false
      });
    },

    // demo
    getDemoData(data) {
      return api.get(userApiBaseUrl + "/demo", {
        params: data,
        shouldJWT: false,
        noLoading: false
      });
    },

    // 送礼
    sendGift(id, data) {
      return api.post(userApiBaseUrl + `/inevent/${id}/live/sendGift`, data, {
        shouldJWT: true
      });
    },

    // 获取公告
    getNotice(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/notice`, {
        shouldJWT: true
      });
    },

    // jc50
    getJc50() {
      return api.get(userApiBaseUrl + `/jc50`, {
        shouldJWT: false
      });
    },

    getAzureToken(id) {
      return api.get(userApiBaseUrl + `/inevent/${id}/issuetoken`, {
        shouldJWT: true,
        noLoading: true
      });
    }

  };
}