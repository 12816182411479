import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { initTheme } from "@/utils/utils"

const routes = [
  // {
  //   path: "/test",
  //   name: "Test",
  //   component: () =>
  //     import(/* webpackChunkName: "Test" */ "../views/Test.vue"),
  //   meta: {
  //     publicPage: true,
  //     shouldLogin: false,
  //   },
  // },
  {
    path: "/entercode",
    name: "EnterCode",
    component: () =>
      import(/* webpackChunkName: "Common" */ "../views/common/EnterCode.vue"),
    meta: {
      publicPage: true,
      shouldLogin: false,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "Common" */ "../views/common/Error.vue"),
    meta: {
      publicPage: true,
      shouldLogin: false,
    },
  },
  // meetdemo
  {
    path: "/demo/meet/:eventType",
    name: "DemoMeetIndex",
    component: () =>
      import(/* webpackChunkName: "DemoMeet" */ "../views/meet/demo/Index.vue"),
    meta: {
      publicPage: true,
      shouldLogin: false,
      pageType: "meetDemo",
      demoType: ["1", "2", "4"],
    },
    children: [
      {
        //默认
        path: "",
        redirect: { name: "DemoMeetEntrance" },
      },
      {
        path: "entrance",
        name: "DemoMeetEntrance",
        component: () =>
          import(
            /* webpackChunkName: "DemoMeet" */ "../views/meet/prePage/Entrance.vue"
          ),
        meta: {
          publicPage: true,
          shouldLogin: false,
          pageType: "meetDemo",
          demoType: ["1", "2", "4"],
        },
      },
      {
        path: "positionselect",
        name: "DemoMeetPositionSelection",
        component: () =>
          import(
            /* webpackChunkName: "DemoMeet" */ "../views/meet/prePage/PositionSelect.vue"
          ),
        meta: {
          publicPage: true,
          shouldLogin: false,
          pageType: "meetDemo",
          demoType: ["2"],
        },
      },
      {
        path: "meeting",
        component: () =>
          import(
            /* webpackChunkName: "DemoMeet" */ "../views/meet/demo/VideoIndex.vue"
          ),
        children: [
          {
            path: "waittingroom",
            name: "DemoMeetWaittingRoom",
            component: () =>
              import(
                /* webpackChunkName: "DemoMeet" */ "../views/meet/meetingPage/WaittingRoom.vue"
              ),
            meta: {
              publicPage: true,
              shouldLogin: false,
              pageType: "meetDemo",
              demoType: ["1", "2", "4"],
            },
          },
          {
            path: "meeting",
            name: "DemoMeetMeeting",
            component: () =>
              import(
                /* webpackChunkName: "DemoMeet" */ "../views/meet/meetingPage/Meeting.vue"
              ),
            meta: {
              publicPage: true,
              shouldLogin: false,
              pageType: "meetDemo",
              demoType: ["1", "2", "4"],
            },
          },
          {
            path: "shootingroom",
            name: "DemoMeetShootingRoom",
            component: () =>
              import(
                /* webpackChunkName: "DemoMeet" */ "../views/meet/meetingPage/ShootingRoom.vue"
              ),
            meta: {
              publicPage: true,
              shouldLogin: false,
              pageType: "meetDemo",
              demoType: ["2"],
            },
          },
          {
            path: "savephoto",
            name: "DemoMeetSavePhoto",
            component: () =>
              import(
                /* webpackChunkName: "DemoMeet" */ "../views/meet/endPage/SavePhoto.vue"
              ),
            meta: {
              publicPage: true,
              shouldLogin: false,
              pageType: "meetDemo",
              demoType: ["2"],
            },
          },
          {
            path: "selectphoto",
            name: "DemoMeetSelectPhoto",
            component: () =>
              import(
                /* webpackChunkName: "DemoMeet" */ "../views/meet/endPage/SelectPhoto.vue"
              ),
            meta: {
              publicPage: true,
              shouldLogin: false,
              pageType: "meetDemo",
              demoType: ["2"],
            },
          },
          {
            path: "editphoto",
            name: "DemoMeetEditPhoto",
            component: () =>
              import(
                /* webpackChunkName: "DemoMeet" */ "../views/meet/endPage/EditPhoto.vue"
              ),
            meta: {
              publicPage: true,
              shouldLogin: false,
              pageType: "meetDemo",
              demoType: ["2"],
            },
          },
          {
            path: "cert",
            name: "DemoMeetCert",
            component: () =>
              import(
                /* webpackChunkName: "DemoMeet" */ "../views/meet/endPage/Cert.vue"
              ),
            meta: {
              publicPage: true,
              shouldLogin: false,
              pageType: "meetDemo",
              demoType: ["1", "4"],
            },
          },
        ],
      },
    ],
  },
  // meet
  {
    path: "/meet",
    name: "MeetIndex",
    component: () =>
      import(/* webpackChunkName: "Meet" */ "../views/meet/Index.vue"),
    meta: {
      publicPage: false,
      shouldLogin: true,
      pageType: "meet",
    },
    children: [
      {
        //默认
        path: "",
        redirect: { name: "Entrance" },
      },
      {
        path: "inquiry",
        name: "Inquiry",
        component: () =>
          import(/* webpackChunkName: "Meet" */ "../views/common/Inquiry.vue"),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "meet",
          haveTheme: true,
        },
      },
      // Evaluate
      {
        path: "evaluate",
        name: "Evaluate",
        component: () =>
          import(/* webpackChunkName: "Meet" */ "../views/common/Evaluate.vue"),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "meet",
          haveTheme: true,
        },
      },
      {
        path: "devicecheck",
        name: "DeviceCheck",
        component: () =>
          import(
            /* webpackChunkName: "Meet" */ "../views/common/DeviceCheck.vue"
          ),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "meet",
        },
      },
      {
        path: "entrance",
        name: "Entrance",
        component: () =>
          import(
            /* webpackChunkName: "Meet" */ "../views/meet/prePage/Entrance.vue"
          ),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "meet",
          haveTheme: true,
        },
      },
      {
        path: "chatroom",
        name: "ChatRoom",
        component: () =>
          import(
            /* webpackChunkName: "Meet" */ "../views/meet/prePage/ChatRoom.vue"
          ),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "meet",
          haveTheme: true,
        },
      },
      {
        path: "positionselect",
        name: "PositionSelection",
        component: () =>
          import(
            /* webpackChunkName: "Meet" */ "../views/meet/prePage/PositionSelect.vue"
          ),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "meet",
          haveTheme: true,
        },
      },
      {
        path: "meeting",
        component: () =>
          import(
            /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/Index.vue"
          ),
        children: [
          {
            path: "waittingroom",
            name: "WaittingRoom",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/WaittingRoom.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
          {
            path: "meeting",
            name: "Meeting",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/Meeting.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
          {
            path: "shootingroom",
            name: "ShootingRoom",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/ShootingRoom.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
          {
            path: "savephoto",
            name: "SavePhoto",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/endPage/SavePhoto.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
          {
            path: "selectphoto",
            name: "SelectPhoto",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/endPage/SelectPhoto.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
          {
            path: "editphoto",
            name: "EditPhoto",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/endPage/EditPhoto.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
          {
            path: "cert",
            name: "Cert",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/endPage/Cert.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
        ],
      },
    ],
  },

  // live
  {
    path: "/live",
    name: "LiveIndex",
    component: () =>
      import(/* webpackChunkName: "Live" */ "../views/live/Index.vue"),
    meta: {
      publicPage: false,
      shouldLogin: true,
      pageType: "live",
    },
    children: [
      {
        //默认
        path: "",
        redirect: { name: "LiveEntrance" },
      },
      {
        path: "livepre",
        component: () =>
          import(
            /* webpackChunkName: "Live" */ "../views/live/prePage/Index.vue"
          ),
        children: [
          {
            path: "",
            redirect: { name: "LiveEntrance" },
          },
          {
            path: "live-entrance",
            name: "LiveEntrance",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/prePage/LiveEntrance.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
        ],
      },
      {
        path: "streaming",
        name: "StreamingIndex",
        component: () =>
          import(
            /* webpackChunkName: "Live" */ "../views/live/streamingPage/Index.vue"
          ),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "live",
          haveTheme: true,
        },
        children: [
          {
            //默认
            path: "",
            redirect: { name: "LiveWait" },
          },
          {
            path: "live-wait",
            name: "LiveWait",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/streamingPage/LiveWait.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
          {
            path: "live-streaming",
            name: "LiveStreaming",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/streamingPage/LiveStreaming.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
          {
            path: "live-end",
            name: "LiveEnd",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/endPage/LiveEnd.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
        ],
      },
    ],
  },
  // live-jcf
  {
    path: "/jc50",
    name: "LiveJCFIndex",
    component: () =>
      import(/* webpackChunkName: "LiveJCF" */ "../views/liveJCF/Index.vue"),
    meta: {
      publicPage: false,
      shouldLogin: false,
      pageType: "liveJFC",
    },
    children: [
      {
        //默认
        path: "",
        redirect: { name: "LiveJCFWait" },
      },
      {
        path: "",
        name: "LiveJCFStreamingIndex",
        component: () =>
          import(
            /* webpackChunkName: "LiveJCF" */ "../views/liveJCF/streamingPage/Index.vue"
          ),
        meta: {
          publicPage: false,
          shouldLogin: false,
          pageType: "liveJFC",
          haveTheme: false,
        },
        children: [
          {
            //默认
            path: "",
            redirect: { name: "LiveJCFWait" },
          },
          {
            path: "live-wait",
            name: "LiveJCFWait",
            component: () =>
              import(
                /* webpackChunkName: "LiveJCF" */ "../views/liveJCF/streamingPage/LiveWait.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: false,
              pageType: "liveJFC",
              haveTheme: false,
            },
          },
          {
            path: "live-streaming",
            name: "LiveJCFStreaming",
            component: () =>
              import(
                /* webpackChunkName: "LiveJCF" */ "../views/liveJCF/streamingPage/LiveStreaming.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: false,
              pageType: "liveJFC",
              haveTheme: false,
            },
          },
        ],
      },
    ],
  },
  // ticket
  {
    path: "/ticket",
    name: "TicketIndex",
    component: () =>
      import(/* webpackChunkName: "Ticket" */ "../views/ticket/Index.vue"),
    meta: {
      publicPage: true,
      shouldLogin: false,
      pageType: "Ticket",
    },
    children: [
      {
        //默认
        path: "",
        redirect: { name: "EnterCode" },
      },
      {
        path: "event/:id",
        name: "TicketPayEvent",
        component: () =>
          import(
            /* webpackChunkName: "Ticket" */ "../views/ticket/PayEvent.vue"
          ),
        meta: {
          publicPage: true,
          shouldLogin: false,
          pageType: "Ticket",
        },
      },
      {
        path: "pay-end",
        name: "TicketPayEnd",
        component: () =>
          import(/* webpackChunkName: "Ticket" */ "../views/ticket/PayEnd.vue"),
        meta: {
          publicPage: true,
          shouldLogin: false,
          pageType: "Ticket",
        },
      },
      {
        path: "/elepay/callback",
        name: "ElepayCallback",
        component: () =>
          import(
            /* webpackChunkName: "Ticket" */ "../views/ticket/elepayCallback.vue"
          ),
        meta: {
          publicPage: true,
          shouldLogin: false,
          pageType: "Ticket",
        },
      },
      {
        path: "checkorder",
        name: "CheckOrder",
        component: () =>
          import(
            /* webpackChunkName: "Ticket" */ "../views/ticket/CheckOrder.vue"
          ),
        meta: {
          publicPage: true,
          shouldLogin: false,
          pageType: "Ticket",
        },
      },
      {
        path: "checkorder/detail",
        name: "CheckOrderDetail",
        component: () =>
          import(
            /* webpackChunkName: "Ticket" */ "../views/ticket/CheckOrderDetail.vue"
          ),
        meta: {
          publicPage: true,
          shouldLogin: false,
          pageType: "Ticket",
        },
      },
    ],
  },

  { path: "/:pathMatch(.*)*", redirect: "/entercode" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // TODO:test
  // store.dispatch(
  //   'setToken',
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyMyIsIm5iZiI6MTYyMDM3MjEwMSwibmlja25hbWUiOiJhdmF0YXIiLCJpc3MiOiJ3ZXNob3ciLCJpZCI6IjIzIiwiZXhwIjoxNjIyOTY0MTAxLCJlbWFpbCI6ImF2YXRhckBnbWFpbC5jb20ifQ.dX7eIBw2gWy81MFHxa4H5fK5jDM1jvyrIB6wSgzUNL6IFNYDw4bVpDLDAbWmT_3pAyNRlhVaXNH0RPIy6Ju3YoXUiMYnfLfsdALQekWZddBqfsyDPIjjo6YsXcPJzN--TnjeuTSIUSDuL2UmO8se9Cx-Z9ayX-J_jMqPOt-urjdn4hXELLEz4gwFBNy--qsQ9t_6430VY7_8Ge2-iX4s-05fGdoIyOXx4ls_BxU8ae-AoafKHDl1Zn7xf_zpR8_TowjVPMRLcAUOBRrmIj9M7zzAVYHPxPGLwyZ0-lHk4PEJyluLhNjK63-arIVNP6ClfhTG9fBbxpgtYKqtM9HXfw'
  // )
  // store.dispatch('setUserType', 'c')

  console.log(to);
  const publicPage = to.meta.publicPage;
  const shouldLogin = to.meta.shouldLogin;
  const token = store.getters.token;
  const pageType = to.meta.pageType;

  if (!publicPage) {
    if (shouldLogin) {
      if (!token) {
        router.replace({ name: "EnterCode" });
      } else {
        // 判断eventType对不对
        const eventType = localStorage.getItem("eventType");
        switch (eventType) {
          case "1":
          case "2":
            if (pageType !== "meet") {
              router.replace({ name: "EnterCode" });
            }
            break;
          case "3":
            if (pageType !== "live") {
              router.replace({ name: "EnterCode" });
            }
            break;
          default:
            break;
        }
      }
    }
  }
  // demo页面判断逻辑
  if (pageType === "meetDemo") {
    const demoEventType = to.params.eventType;
    const demoType = to.meta.demoType || [];
    if (demoType.indexOf(demoEventType) === -1) {
      router.replace({ name: "EnterCode" });
    }
  }

  next();
});

router.afterEach((to, from) => {
  console.log("afterEach");
  const haveTheme = to.meta.haveTheme
  if (!haveTheme) {
    initTheme()
  }
  setTimeout(() => {
    store.dispatch("setWinHeight", window.innerHeight);
    store.dispatch("setWinWidth", window.innerWidth);
    console.log(window.innerHeight);
  }, 500);
});

export default router;
