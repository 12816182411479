import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/anime.css";
import "./assets/style/common.less";
import "vue-neat-modal/dist/vue-neat-modal.css";
import Toaster from "@meforma/vue-toaster";
import moment from "moment";
import { initTheme } from "@/utils/utils";
import i18n from "./lang";
import 'moment/locale/ja';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';
import 'moment/locale/en-gb';
moment.locale("ja");
const app = createApp(App);

app.config.globalProperties.$formatDate = (d, f) => {
  const locale = i18n.global.locale || "ja";
  let momentLocale = "";

  switch (locale) {
    case 'en':
      momentLocale = 'en-gb';
      break;

    case 'zh':
      momentLocale = 'zh-cn';
      break;

    default:
      momentLocale = locale;
      break;
  }

  console.log(i18n.global.locale);
  return moment(d).locale(momentLocale).format(f);
};

app.use(Toaster).use(i18n).provide("toast", app.config.globalProperties.$toast);
app.use(store).use(router).mount("#app");

console.log = function (log) {
  return process.env.VUE_APP_NO_CONSOLE == "1" ? function () {} : log;
}(console.log);

window["$toast"] = app.config.globalProperties.$toast;

const addCommasToNumber = num => {
  // 将数字转换为字符串
  const numStr = num.toString(); // 将字符串拆分成整数部分和小数部分

  const [intStr, decimalStr] = numStr.split('.'); // 对整数部分进行逗号分隔处理

  const intWithCommas = intStr.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 如果有小数部分，则重新组合成带小数点的字符串

  if (decimalStr) {
    return `${intWithCommas}.${decimalStr}`;
  } else {
    return intWithCommas;
  }
};

app.config.globalProperties.$i18n = i18n;
app.config.globalProperties.$priceFormat = addCommasToNumber; // 初始化主题

initTheme();