export const m = {
  // login
  pleaseInputNickName: '닉네임을 입력해 주십시요',
  pleaseInputNickNameTip: '6자 이내의 닉네임을 입력해 주십시요',
  pleaseInputCode: '인증 코드를 입력해 주십시요',
  certification: '인증',
  useGuideClick: '이용 안내는 여기',
  noEventTip: '참가할 수 있는 이벤트가 없습니다',
  certificationFailed: '인증에 실패하였습니다',
  subtitleTip: '자막 언어를 선택하세요',
  // device test
  deviceTest: '기기 테스트',
  permissionPermitTip: '카메라 및 마이크에 대한 권한을 허용해 주십시요.',
  deviceToConnection: '기기에 연결중',
  deviceTestStartTip: '기기 및 인터넷 연결에 성공했습니다. 테스트를 시작해 주십시요.',
  deviceTestFailedTip: '기기 및 인터넷 연결에 실패했습니다. 다시한번 기기를 확인해 주십시요.',
  deviceTestStart: '테스트 시작',
  skip: '건너뛰기',
  cameraSelection: '카메라 선택',
  startingCamera: '카메라 기동중',
  testCameraTip: '카메라가 기동중입니까?',
  speakerSelection: '스피커 선택',
  devicePlayMusicTip: '재생 버튼으로 음악이 흐릅니다. ',
  devicePlayMusicTip2: '볼륨을 조정해 주십시요.',
  testMicTip: '무언가 이야기 하죠',
  micSelection: '마이크 선택',
  testMicTip2: '소리를 낼 때 분홍색 바가 움직이고 있습니까？',
  netStatus: '인터넷 상황',
  inTest: '테스트중',
  netTestTip: '테스트중입니다. 약1~2분정도 걸릴 수 있습니다. 잠시 후 자동으로 화면이 전환되지 않으면 네트워크 연결 상태가 불안정할 가능성이 높습니다.',
  netTestTip2: '네트워크 환경을 확인하신 후 브라우저 새로고침 또는 재부팅 해보시기 바랍니다.',
  netResTip: '현재 네트워크 상태: 좋음',
  netResTip2: '이대로 네트워크 환경을 안정시켜 주십시요',
  netResTip3: '현재 네트워크 상태: 별로 좋지 않음',
  netResTip4: '대화가 지연될 수 있으니 네트워크 환경이 좋은 곳으로 이동해 주십시요.',
  netResTip5: '현재 네트워크 상태: 에러',
  netResTip6: '네트워크를 찾을 수 없습니다.이동하시거나 권장 환경 확인 부탁드립니다.',
  netResTip7: '현재 네트워크 상태: 매우 나쁨',
  netResTip8: '대화가 불가능할 수 있으니 반드시 네트워크 환경이 좋은 곳으로 이동하시기 바랍니다.',
  toTestResult: '테스트 결과에',
  testResult: '테스트 결과',
  camera: '카메라',
  speaker: '스피커',
  microphone: '마이크',
  checkOk: '정상',
  checkFailed: '이상',
  unknown: '미지',
  good: '좋음',
  notGood: '좋지않음',
  error: '에러',
  bad: '나쁨',
  redo: '다시하기',
  completed: '완료',
  devicePageErrorTip: '단말기 또는 브라우저는 사용할 수 없습니다',
  devicePageErrorTip2: '권장 환경을 확인해 주십시요.',
  devicePageErrorTip3: '권장환경',
  devicePageErrorTip4: 'Windows 10 최신 버전의 Google Chrome, Microsoft Edge',
  devicePageErrorTip5: '※ Internet Explorer 미지원',
  devicePageErrorTip6: 'macOS 11.0 이상(최신 버전의 Google Chrome, Safari)',
  devicePageErrorTip7: '스마트폰・태블릿',
  devicePageErrorTip8: 'Android 8이상(최신 버전의 구글 크롬)',
  devicePageErrorTip9: 'iOS 12.0이상(최신 버전의 Safari)',
  devicePageErrorTip10: '모바일 단말기',
  devicePageErrorTip11: 'iPhone: 아이폰7이후(7포함)',
  devicePageErrorTip12: 'Android：최근 3년 이내에 출시된 단말기',
  devicePageErrorTip13: '비고：Huawei단말기는 지원되지 않음(사용상 제한된 기능이 있기 때문에)',
  devicePageRemote: "원격",
  devicePageLocal: "지역",
  devicePageTrtcTestTip: '말할 때 자신의 목소리가 들리나요?',
  devicePageTrtcTestTip2: '로컬 측과 리모트 측 모두 카메라의 이미지를 볼 수 있습니까?',
  // Entrance
  shootingGuideTip: '〈촬영 가이드〉',
  shootingGuideTip2: '핸드폰을 들어 올려 주십시요',
  shootingGuideTip3: '손을 똑바로 펴 주십시요',
  shootingGuideTip4: '흰 테두리를 따라 탤런트와 포즈를 조정해 주십시요',
  shootingGuideTip5: '※ 주의점',
  shootingGuideTip6: '스마트폰을 사용해 주십시요',
  shootingGuideTip7: '책상위에 두지 마세요',
  shootingGuideTip8: '이런 사진을 찍을 수 있습니다！',
  shootingGuideTip9: '〈주의사항〉',
  shootingGuideTip10: '주의 사항을 읽고,',
  shootingGuideTip11: '{eventExpress}시작 10분전이 되면「동의하고 다음」버튼을 누를 수 있습니다.',
  shootingGuideTip12: '{eventExpress}시작할 때까지 반드시 대기실에서 기다려 주십시오.네트워크 불안정을 초래하는 원인이 되므로 화면은 열어두고 앱이나 브라우저사이의 전환을 삼가해 주십시오.',
  shootingGuideTip13: '부득이한 사유로 이탈하여 돌아 왔을 때 화면이 뜨지 않는 경우가 발생하면 페이지를 새로고침 해 주십시요.',
  shootingGuideTip14: '〈금지사항〉',
  shootingGuideTip15: '비방중상, 욕설, 기타 괴롭힘으로 볼 수 있는 행위, 디바이스 녹화 기능, 스크린샷 사용을 금지합니다.',
  shootingGuideTip16: '품질향상을 위해 일부 기록을 하고 있습니다.이해해 주시길 바랍니다.',
  agreementNext: '동의하고 다음에',
  // PositionSelect
  leftRightPositionSelection: '좌우위치 선택',
  left: '왼쪽',
  right: '오른쪽',
  leftRightDemoTip: '※데모 때문에 오른쪽밖에 선택할 수 없습니다',
  positionSelectTip: '우선 좌우 위치를 정해 주십시요',
  // ChatRoom
  chatRoomTip: '시작까지 잠시 기다려 주십시요',
  chatRoomTip2: '표시 메시지의 상한에 도달했습니다',
  chatRoomTip3: '더이상의 메세지는 없습니다',
  chatRoomErrorTip: '심사규칙으로 인해 발언이 거부되었습니다',
  sendComment: '코멘트하기',
  chatRoomName: '{nickName}의 채팅방',
  // meetIndex
  meetIndexErrorTip: '반드시 카메라와 마이크의 접속을 허용해 주십시요',
  meetIndexErrorTip2: '네트워크 또는 디바이스 오류가 발생했습니다.페이지를 다시 불러오십시오.',
  meetIndexErrorTip3: '카메라 또는 마이크 오류가 발생했습니다.페이지를 다시 불러오십시오.',
  meetIndexErrorTip4: '동영상 또는 음성에 오류가 발생했습니다.',
  meetIndexErrorTip5: '카메라 또는 마이크 오류가 발생했습니다.다시 한 번 허락해 주십시요.',
  meetIndexErrorTip6: '<div class="tl">카메라와 마이크가 차단되어 있습니다<br />브라우저의「새로고침」아이콘<img src="/img/rotate.png" height="20" />을 클릭하여 페이지를 업데이트 하십시오.</div>',
  meetIndexErrorTip7: '<div class="tl">카메라와 마이크가 차단되어 있습니다<br />카메라와 마이크에 대한 접근 권한을 허용 할 필요가 있습니다.브라우저 주소창의 차단된 「잠금」아이콘<img src="/img/lock.png" height="20" />을 클릭하고,「권한」을 클릭하여 카메라와 마이크를 오픈해 주세요.</div>',
  meetIndexErrorTip8: '투샷 유효기간이 지났습니다!',
  meetIndexErrorTip9: '마이크의 초기화에 실패했습니다',
  // Meeting
  meetingTip: '네트워크가 불안정합니다.',
  meetingTip2: '확인 해 주십시요.',
  meetingTip3: '이 다음에는 투샷 촬영룸으로',
  // ShootingRoom
  shootingRoomTip: '투샷 촬영룸',
  shootingRoomTip2: '촬영을 준비해 주십시요',
  shootingRoomTip3: '카운트다운 종료 후, 촬영이 자동적으로 실시됩니다.',
  shootingRoomTip4: '사진은 두장을 연속 촬영합니다.',
  // WaittingRoom
  waittingRoomStatus: '대기중',
  waittingRoomCameraTitle: '카메라 영상',
  waittingRoomMicTest: '마이크 테스트',
  waittingRoomVoiceTest: '음량 테스트',
  waittingRoomTip: '*부탁사항',
  waittingRoomTip2: '디바이스 녹화 기능, 스크린샷 사용을 금지합니다.',
  waittingRoomTip3: '위의 마이크・스피커 아이콘으로 최종 확인을 해 주십시요.',
  waittingRoomTip4: '음량 테스트 버튼을 선택하여 음량을 조정해 주십시요',
  waittingRoomTip5: '영상이나 문자는 반전하지 않고 올바르게 상대에게 비친다.',
  // Cert
  certEventEnd: '{eventExpress}종료',
  certEventEndTip: '{eventExpress}수고하셨습니다!',
  certErrorTip: '사인 실패했습니다!',
  certErrorTip2: '아래 양식에 문의해 주십시요',
  certErrorTip3: '사인의 유효기간이 지났습니다!',
  certErrorTip4: '사인 실패했습니다',
  certTip: '이미지를 보존해서 SNS등으로 모두에게 자량해요!',
  certTip2: '{eventExpress}관련 문의는 이쪽',
  certTip3: '{eventExpress}를 평가해 보세요',
  certMarking: '작성중',
  certificate: '참가증',
  joinTime: '참가시간',
  liverEvent: '{nickName}의{eventExpress}',
  mrMiss: '{nickName}씨',
  // EditPhoto
  editPhotoTitle: '투샷 편집룸',
  editPhotoTip: '대상을 선택하고 편집을 해 주십시요.',
  editPhotoTip2: '아래 이미지를 확인해 주십시요',
  standardStyle: '표준 스타일',
  particularAdjustment: '세세한 조정',
  positionAdjustment: '위치 조정',
  dark: '어둡다',
  cold: '차갑다',
  warm: '따뜻하다',
  bright: '밝다',
  brightness: '밝기',
  contrast: '대조',
  saturation: '선명함',
  editComplete: '편집완료',
  editPhotoErrorTip: '사진을 오려내는데 실패했습니다',
  editPhotoErrorTip2: '운영사무국에서 투샷 사진을 합성할 수 있는지 조사 할테니까',
  editPhotoErrorTip3: '아래 양식에 문의해 주십시요',
  // SavePhoto
  savePhotoTip: '투샷이 발행됐습니다!스마트폰의 경우 이미지를 길게 누르면 단말기에 보존할 수 있습니다.',
  savePhotoTip2: '이 페이지는,{expirationTime}까지 유효.',
  savePhotoTip3: '보존이 안되면 아래 버튼을 클릭 해 주십시요.',
  savePhotoTip4: '이미지를 열다',
  savePhotoTip5: '보존중',
  savePhotoErrorTip: '문의는 이쪽',
  savePhotoErrorTip2: '시청관련 오류는 이쪽',
  savePhotoDemoExp: '2125년12월30일',
  // SelectPhoto
  selectPhotoTitle: '투샷 편집룸',
  selectPhotoTip: '원하는 한장을 골라 주십시요.',
  selectPhotoErrorTip: '투샷 촬영, 사진 업로드에 실패 했습니다',
  selectPhotoErrorTip2: '운영 사무국에서 투샷 사진을 합성할 수 있는지 조사 할테니까',
  selectPhotoErrorTip3: '아래 양식에 문의해 주십시요',
  // Inquiry
  inquiryEventEnd: '{eventExpress}종료',
  inquiryEventEndTip: '수고하셨습니다！',
  // Evaluate
  evaluateTip: '음성 및 동영상의 품질은 어떻습니까?',
  evaluateGood: '아주좋음',
  evaluateBad: '매우 나쁜',
  // LiveEntrance
  liveEntranceTitle: 'Live 전송',
  liveEntranceTip: '무단으로 전송 내용의 녹화·녹음, 불특정 다수가 열람할 수 있는 장소(SNS등 포함)로의 투고·전재는 금지합니다.',
  liveEntranceTip2: '이러한 행위가 발각되어 주최자가 필요하다고 판단한 경우에는 경찰에 신고하는 등 수사기관의 협조를 구할 수 있습니다.',
  liveEntranceTip3: '매너를 지키고 즐겨 주십시요.',
  liveEntranceBtn: '동의하고, 시청하기',
  // LiveWait
  liveWait: 'Live 준비중',
  liveWaitTip: '오랫동안 재생되지 않는 경우 페이지를 다시 표시하십시오.',
  // LiveStreaming
  liveStreamingTitle: '라이브전송「{title} 」',
  liveStreamingStartTime: '라이브전송개시일',
  liveStreamingWatchNewComment: '새로운 코멘트 보기',
  liveStreamingGift: '{nickName}씨는{giftName}을(를) 보냈어',
  liveStreamingGift2: '{giftName}을(를) 보냈어',
  liveStreamingWelcome: 'WeShoW 라이브 전송에 오신 것을 환영합니다.',
  liveStreamingTip: '버튼을 클릭하여 라이브를 시작합시다!',
  liveStreamingWording1: '라이브 전송 불러오는 중, 잠시만 기다려주십시요.',
  liveStreamingWording2: '라이브 전송 불러오는 중, 잠시만 기다려주십시요.',
  liveStreamingWording3: '비디오 디코딩 오류.',
  liveStreamingWording4: '현재 시스템 환경에서는 이 비디오 포맷의 재생이 지원되지 않습니다.',
  liveStreamingWording5: '현재 시스템 환경에서는 이 비디오 포맷의 재생이 지원되지 않습니다.',
  liveStreamingWording10: 'file 프로토콜에서 플레이어를 사용하지 마십시오.비디오를 재생할 수 없게 될 우려가 있습니다.',
  liveStreamingWording11: '사용 변수가 잘못되었습니다.플레이어 호출 코드를 확인하십시오.',
  liveStreamingWording12: '비디오 재생 주소를 입력하십시오.',
  liveStreamingWording13: 'Live전송은 종료되었으니 나중에 와주십시요.',
  liveStreamingWording1001: '네트워크 오류. 네트워크 설정 또는 링크가 올바른지 확인해 주십시요.',
  liveStreamingWording1002: '비디오 취득에 실패했습니다.링크가 유효한지 확인해 주십시요.',
  liveStreamingWording2001: '라이브 전송 불러오는 중, 잠시만 기다려주십시요.',
  liveStreamingWording2002: '라이브 전송 불러오는 중, 잠시만 기다려주십시요.',
  liveStreamingWording2003: '라이브 전송이 안되고 있습니다.',
  liveStreamingWording2032: '비디오 취득에 실패했습니다.링크가 유효한지 확인해 주십시요.',
  liveStreamingWording2048: '비디오 파일을 가져올 수 없습니다.크로스 도메인 액세스가 거부됩니다.',
  liveStreamingErrorTip: '다른 곳에서 로그인하고 있어 강제로 로그아웃 되었습니다.',
  liveStreamingErrorTip2: '심사규칙으로 인해 발언이 거부되었습니다',
  // LiveEnd
  liveEnd: 'LIVE는 종료되었습니다',
  // CheckOrder
  checkOrderTip: '메일을 입력해 주십시요',
  checkOrderTip2: '주문번호를 입력해 주십시요',
  checkOrderTip3: '※주문 번호는 구매 완료 시 이메일에 기재되어 있습니다.',
  checkOrderErrorTip: '올바른 메일을 입력해 주십시요',
  checkOrderErrorTip2: '주문번호를 설정해 주십시요',
  // CheckOrderDetail
  checkOrderDetailErrorTip: '에러',
  checkOrderDetailErrorTip2: `구매 내용을 확인할 수 없습니다.
이메일 주소와 주문 번호를 다시 확인해주세요.`,
  checkOrderDetailTip: '구매 내용',
  // PayEnd
  payEndErrorTip: '에러',
  payEndErrorTip2: '지불 결과를 얻을 수 없기 때문에,',
  payEndErrorTip3: '나중에 지불 결과를 등록한Email：{email}로 송신합니다.',
  payEndErrorTip4: '송신 메일 주소, 휴지통에 들어가 있을 가능성이 있습니다.',
  payEndTip: '구매 내용',
  payEndTip2: '상기내용을 Email： {email}로도 송신합니다.',
  // PayEvent
  remainingCount: '남은 매수',
  eventIntroduction: '이벤트 소개',
  liverIntroduction: '라이버 소개',
  contactUs: '문의',
  toWebContactUs: 'Web폼으로 문의하기',
  payEventContactUsTip: '문의 접수는, 본 이벤트의 신청·참여 방법 등만 접수 가능합니다.',
  payEventContactUsTip2: '출연자 개인에 대한 질문이나 자세한 이벤트 내용은, 회신이 불가하오니 미리 양해 부탁드립니다.',
  payEventContactUsTip3: '문의가능시간:평일10:00～18:00',
  inputScreen: '입력화면',
  verificationScreen: '확인화면',
  eventTitle: '이벤트명',
  personallyIdentifiableInformation: '개인정보',
  purchasePrice: '구입가격',
  payEventTip: '아직 결제 확인이 안 되는 오더가 있습니다.오더를 취소하겠습니까?',
  payEventTip2: '메일을 입력해주십시요',
  payEventTip3: '구매 매수를 입력하세요',
  payEventTip4: '이메일 주소를 다시 입력해주세요',
  payEventTip5: '※확인을 위해 다시 입력해주세요.',
  reconfirmation: '재확인',
  payEventErrorTip: '이름을 입력해주십시요',
  payEventErrorTip2: '올바른 메일을 입력해주십시요',
  payEventErrorTip3: '재고없음',
  payEventErrorTip4: '구매 한도를 초과했습니다',
  payEventErrorTip5: '두 번 입력한 이메일 주소가 일치하지 않습니다',
  payEventErrorTip6: '확인용 이메일을 입력해주세요',
  payEventErrorTip7: '잔여 수량이 부족합니다. 다시 입력해주세요.',
  payMaxOnce: '한 번 구매 최대 매수',
  // ua check error
  uaCheckErrorTitle: '현재 액세스하는 기기 또는 브라우저에서는 사용할 수 없습니다.',
  uaCheckErrorTitle2: `대단히 죄송합니다. 
현재 고객님께서 접속하신 기기 또는 브라우저에서는 신청하실 수 없습니다.`,
  uaCheckErrorTip: '권장 환경을 확인하시고 권장 환경에서 아래 URL을 복사하여 붙여넣은 후 다시 액세스하십시오.',
  uaCheckErrorTip2: '신청하고자 하는 제품 판매 URL을 복사하시고, 아래 권장 환경에서 다시 접속해주시기 바랍니다.',
  // common
  return: '돌아가기',
  no: '아니오',
  yes: '네',
  fan: '팬',
  errorTip: '에러가 발생했습니다',
  remaining: '나중에',
  remaining2: '나머지',
  user: '사용자',
  day: '일',
  hours: '시간',
  minutes: '분',
  seconds: '초',
  // 简写的时间
  d: '일',
  h: '시간',
  m: '분',
  s: '초',
  YYYYMMDD: 'YYYY년MM월DD일',
  aboutTime: '약',
  more: '더보기',
  send: '송신',
  reload: '다시읽기',
  recovery: '회복',
  permission: '허가',
  redo: '다시하기',
  sendCompleted: '송신했습니다',
  sendError: '송신 실패했습니다',
  toNext: '다음',
  toInquiry: '문의로',
  save: '보존',
  check: '체크',
  toOrderInquiry: '구매 내용 확인으로',
  photoProcessing: '사진처리중',
  eventInfo: '이벤트 정보',
  title: '제목',
  liver: '출연자',
  schedule: '스케줄',
  start: '개시',
  end: '종료',
  notice: '공지',
  reservationNumber: '예약 매수',
  ticketsNumber: '{count}매',
  tickets: '매',
  authCode: '인증코드',
  cautions: '주의사항',
  distributor: '판매원',
  isCancel: '취소 했습니다',
  ticketDetail: '티켓 상세',
  eventDetail: '이벤트 상세',
  inConfirmation: '확인중',
  EBCompanyName: '주식회사 이비즈니스',
  joinUrl: '참가URL',
  useGuide: '이용안내',
  buy: '구입',
  cantBuy: '판매시간외',
  isNoStock: '재고없음',
  name: '이름',
  userAgree: `<a
  href="https://guide.weshow.co.jp/terms_user"
  target="_blank"
  class="cInherit unl"
  >사용자 이용약관</a
>에 동의합니다`,
  policyAgree: `<a
href="https://guide.weshow.co.jp/privacy_policy"
target="_blank"
class="cInherit unl"
>개인정보 취급방침</a
>에 동의합니다`,
  termsUser: `사용자 이용 약관`,
  privacyPolicy: `개인정보 취급방침`,
  termsLaw: `특정 상거래법에 근거한 표기`,
  loading: '로딩'
};