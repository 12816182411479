import { createAPI } from "./create-api";
import auth from "./module/auth";
import { showLoading, hideLoading, getLang } from "../utils/utils";
import store from "../store";
import router from "@/router";

const api = createAPI();
//请求拦截器
api.interceptors.request.use(
  (config) => {
    const data = config.data || {};
    let shouldJWT = config.shouldJWT || data.shouldJWT;
    if (shouldJWT && store.getters.token) {
      config.headers["Authorization"] = `Bearer ${store.getters.token}`;
    }
    config.headers['Accept-Language'] = getLang()
    if (data.shouldJWT !== undefined) {
      delete config.data.shouldJWT;
    }
    const noLoading = config.noLoading;
    console.log(config);
    if (!noLoading) {
      showLoading();
    }
    return config;
  },
  (error) => {
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    const config = response.config;
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return response;
  },
  (error) => {
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    const status = error?.response?.status;
    switch (status) {
      case 400:
        const errorType = error.response.data.type;
        const messages = error.response.data.messages || [];
        switch (errorType) {
          case "s":
            // 系统级别错误
            router.replace({
              name: "Error",
              query: {
                errorData: window.btoa(
                  encodeURIComponent(JSON.stringify(messages))
                ),
              },
            });
            break;
          case "b":
            // 商务级别错误
            messages.forEach((item) => {
              window.$toast.error(item, {
                position: "top",
              });
            });
            break;
          default:
            break;
        }
        break;
      case 500:
      case 403:
      case 404:
        router.replace({ name: "Error" });
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);
export const authApi = auth(api);
